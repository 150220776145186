import * as Schema from 'schema-dts'

export const NYT_ORGANIZATION: Schema.Organization = {
  '@type': 'Organization',
  '@id': 'https://www.nytimes.com/#publisher',
  name: 'The New York Times',
  url: 'https://www.nytimes.com/',
  sameAs: [
    'https://www.facebook.com/nytimes/',
    'https://twitter.com/nytimes',
    'https://www.instagram.com/nytimes/',
    'https://www.youtube.com/user/TheNewYorkTimes',
    'https://www.linkedin.com/company/the-new-york-times',
    'https://www.wikidata.org/wiki/Q9684',
    'https://en.wikipedia.org/wiki/The_New_York_Times',
  ],
  logo: {
    '@type': 'ImageObject',
    url: 'https://static01.nyt.com/images/misc/NYT_logo_rss_250x40.png',
    height: { '@type': 'QuantitativeValue', value: 40 },
    width: { '@type': 'QuantitativeValue', value: 250 },
  },
}

export const NYT_COOKING_ORGANIZATION: Schema.Organization = {
  '@type': 'Organization',
  '@id': 'https://cooking.nytimes.com#Organization',
  name: 'NYT Cooking',
  url: 'https://cooking.nytimes.com/',
  alternateName: ['nytimes cooking', 'New York Times Cooking'],
  logo: {
    '@type': 'ImageObject',
    url: 'https://static01.nyt.com/applications/cooking/47f49a4/assets/SiteLogoBlack-112x112.svg',
    height: { '@type': 'QuantitativeValue', value: 112 },
    width: { '@type': 'QuantitativeValue', value: 112 },
  },
  sameAs: [
    'https://www.facebook.com/nytcooking/',
    'https://www.instagram.com/nytcooking/',
    'https://twitter.com/nytimes',
    'https://www.youtube.com/c/NYTCooking',
    'https://www.tiktok.com/@nytcooking',
    'https://apps.apple.com/us/app/nyt-cooking/id911422904',
    'https://play.google.com/store/apps/details?id=com.nytimes.cooking&hl=en_US&gl=US',
  ],
  parentOrganization: NYT_ORGANIZATION,
}
