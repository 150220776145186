import { env } from 'next-runtime-env'
import { NYT_COOKING_ORGANIZATION } from 'pages/structuredData'
import * as Schema from 'schema-dts'

export const META_TITLE = 'Recipes and Cooking Guides From The New York Times'
export const META_DESCRIPTION =
  'NYT Cooking is the digital source for thousands of the best recipes from The New York Times along with how-to guides for home cooks at every skill level.'
export const META_TYPE = 'website'
export const META_IMAGE =
  'https://static01.nyt.com/applications/cooking/cad3c48/assets/hp-og-image.jpg'
export const META_SOCIAL_DESCRIPTION =
  'NYT Cooking is the digital source for thousands of the best recipes from The New York Times along with how-to guides for home cooks at every skill level. Discover new recipes that are tried, tested, and truly delicious with NYT Cooking.'

export const JSON_LD: Schema.WithContext<Schema.WebSite> = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  url: env('NEXT_PUBLIC_APP_BASE_URL'),
  publisher: NYT_COOKING_ORGANIZATION,
  potentialAction: {
    '@type': 'SearchAction',
    target: {
      '@type': 'EntryPoint',
      urlTemplate: `${env(
        'NEXT_PUBLIC_APP_BASE_URL',
      )}/search?q={search_term_string}`,
    },
    // @ts-expect-error - Annotated action. See: https://developers.google.com/search/docs/appearance/structured-data/sitelinks-searchbox#website
    'query-input': 'required name=search_term_string',
  },
}
